import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TownsService {

  constructor() { }

  public getTownData(): any[] {
    return [
      {
        state: 'CO',
        county: 'Broomfield',
        glAcctNumber: 324,
        facility: 'Broomfield SNF 324',
        town: 'Broomfield County, CO',
        countyRate: 1.002,
        stateRate: 1.0086
      },
      {
        state: 'NC',
        county: 'Bladen',
        glAcctNumber: 583,
        facility: 'Elizabethtown 583',
        town: 'Bladen County, NC',
        countyRate: 0.7926,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Buncombe',
        glAcctNumber: 273,
        facility: 'WNC Baptist Home SNF 273',
        town: 'Buncombe County, NC',
        countyRate: 0.8701,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Cabarrus',
        glAcctNumber: 271,
        facility: 'Taylor Glen SNF 271',
        town: 'Cabarrus County, NC',
        countyRate: 0.9337,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Cumberland',
        glAcctNumber: 252,
        facility: 'Highland House of Fayetteville 252',
        town: 'Cumberland County, NC',
        countyRate: 0.7822,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Davidson',
        glAcctNumber: 398,
        facility: 'Mountain Vista Health Park 398',
        town: 'Davidson County, NC',
        countyRate: 0.9249,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Davie',
        glAcctNumber: 559,
        facility: 'Bermuda Village 559',
        town: 'Davie County, NC',
        countyRate: 0.9249,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Durham',
        glAcctNumber: 574,
        facility: 'The Forest at Duke SNF 574',
        town: 'Durham County, NC',
        countyRate: 0.9658,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Forsyth',
        glAcctNumber: 269,
        facility: 'Brookridge SNF 269',
        town: 'Forsyth County, NC',
        countyRate: 0.9249,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Franklin',
        glAcctNumber: 535,
        facility: 'Louisburg Nursing Center 535',
        town: 'Franklin County, NC',
        countyRate: 0.9426,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Granville',
        glAcctNumber: 377,
        facility: 'Universal Hlth of Oxford 377',
        town: 'Granville County, NC',
        countyRate: 0.9658,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Guilford',
        glAcctNumber: 351,
        facility: 'Friends Homes Guilford 351',
        town: 'Guilford County, NC',
        countyRate: 0.9121,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Guilford',
        glAcctNumber: 352,
        facility: 'Friends Homes West 352',
        town: 'Guilford County, NC',
        countyRate: 0.9121,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Moore',
        glAcctNumber: 536,
        facility: 'Pinehurst NC 536',
        town: 'Moore County, NC',
        countyRate: 0.7926,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Person',
        glAcctNumber: 537,
        facility: 'Roxboro Healthcare Center 537',
        town: 'Person County, NC',
        countyRate: 0.9658,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Rockingham',
        glAcctNumber: 290,
        facility: 'Countryside Manor SNF 290',
        town: 'Rockingham County, NC',
        countyRate: 0.9121,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Rockingham',
        glAcctNumber: 249,
        facility: 'Morehead Nursing Center 249',
        town: 'Rockingham County, NC',
        countyRate: 0.9121,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Vance',
        glAcctNumber: 245,
        facility: 'Senior Citizens Home 245',
        town: 'Vance County, NC',
        countyRate: 0.7926,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Wake',
        glAcctNumber: 332,
        facility: 'The Cardinal at North Hills SNF 332',
        town: 'Wake County, NC',
        countyRate: 0.9426,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Yadkin',
        glAcctNumber: 571,
        facility: 'Yadkin Care Center 571',
        town: 'Yadkin County, NC',
        countyRate: 0.9249,
        stateRate: 0.7926
      },
      {
        state: 'NC',
        county: 'Wake',
        glAcctNumber: 560,
        facility: 'Windsor Point 560',
        town: 'Wake County, NC',
        countyRate: 0.9426,
        stateRate: 0.7926
      },
      {
        state: 'OH',
        county: 'Hamitlon',
        glAcctNumber: 10024,
        facility: 'Airy Hills at North Bend Crossing',
        town: 'Hamilton County, OH',
        countyRate: 0.9461,
        stateRate: 0.8094
      },
      {
        state: 'TX',
        county: 'Brazos',
        glAcctNumber: 679,
        facility: 'St Joseph Manor 679',
        town: 'Brazos County, TX',
        countyRate: 0.8637,
        stateRate: 0.8211
      },
      {
        state: 'TX',
        county: 'Burleson',
        glAcctNumber: 681,
        facility: 'Burleson St Joseph Manor 681',
        town: 'Burleson County, TX',
        countyRate: 0.8637,
        stateRate: 0.8211
      },
      {
        state: 'TX',
        county: 'Cooke',
        glAcctNumber: 833,
        facility: 'River Valley Health 833',
        town: 'Cooke County, TX',
        countyRate: 0.8211,
        stateRate: 0.8211
      },
      {
        state: 'TX',
        county: 'Grayson',
        glAcctNumber: 834,
        facility: 'Meadowbrook 834',
        town: 'Grayson County, TX',
        countyRate: 0.8032,
        stateRate: 0.8211
      },
      {
        state: 'TX',
        county: 'Grayson',
        glAcctNumber: 835,
        facility: 'Terrace at Denison 835',
        town: 'Grayson County, TX',
        countyRate: 0.8032,
        stateRate: 0.8211
      },
      {
        state: 'TX',
        county: 'Navarro',
        glAcctNumber: 394,
        facility: 'Country Meadows 394',
        town: 'Navarro County, TX',
        countyRate: 0.8211,
        stateRate: 0.8211
      },
      {
        state: 'TX',
        county: 'Stephens',
        glAcctNumber: 836,
        facility: 'Villa Haven 836',
        town: 'Stephens County, TX',
        countyRate: 0.8211,
        stateRate: 0.8211
      },
      {
        state: 'VA',
        county: 'Virginia Beach',
        glAcctNumber: 501,
        facility: 'Beth Sholom Home of Eastern VA 501',
        town: 'Virginia Beach County, VA',
        countyRate: 0.8796,
        stateRate: 0.7734
      }
    ];
  }
}
