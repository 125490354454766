import { Component, OnInit } from '@angular/core';
import { TownsService } from 'src/app/services/towns.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-urban',
  templateUrl: './urban.component.html',
  styleUrls: ['./urban.component.scss']
})
export class UrbanComponent implements OnInit {

  townsData;
  currentTown = {
    countyRate: 0
  };

  rate = 'countyRate';

  c5 = 98.83;
  e5 = 98.83;
  g5 = 98.83;
  i5 = 98.83;
  k5 = 98.83;
  b4 = 30;
  b11;
  b12;
  b13;
  b14;
  b15;
  b16;
  b17;
  b18;
  b19;
  b20;
  b21;
  b22;
  b23;
  b24;
  b5;
  b6;
  b7;
  b8;
  b9;
  c4 = 1.85;
  d4;
  e4 = 0.96;
  f4;
  g4 = 1.64;
  h4;
  i4 = 1.67;
  j4;
  k4 = 1.46;
  l4;
  m4;
  m5;
  m6;
  m7;
  m8;
  m9;
  n4;
  o4;

  constructor(private towns: TownsService) { }

  ngOnInit(): void {
    this.townsData = this.towns.getTownData();
    this.currentTown = this.townsData[0];
  }

  public townChange(event): void {
    this.currentTown = _.find(this.townsData, (town) => {
      return town.town === event;
    });
  }

  public c6(): any {
    return this.c5 * 0.709;
  }

  public d7() {
    return this.b7 * this.c7();
  }

  public d8(): any {
    return this.b8 * this.c8();
  }

  public e6(): any {
    return this.e5 * 0.709;
  }

  public e8() {
    return this.e6() * this.e7();
  }

  public g6(): any {
    return this.g5 * 0.709;
  }

  public i6(): any {
    return this.i5 * 0.709;
  }

  public c7() {
    return this.currentTown[this.rate];
  }

  public e7() {
    return this.currentTown[this.rate];
  }

  public g7() {
    return this.currentTown[this.rate];
  }

  public i7() {
    return this.currentTown[this.rate];
  }

  public k7() {
    return this.currentTown[this.rate];
  }

  public j6() {
    return this.b6 * this.i6();
  }

  public l6() {
    return this.b6 * this.k6();
  }

  public k6(): any {
    return this.k5 * 0.709;
  }

  public c10(): any {
    return this.c8() + this.c9();
  }

  public c11(): any {
    return this.c10() * this.c4;
  }

  public e10(): any {
    return this.e8() + this.e9();
  }

  public g10(): any {
    return this.g8() + this.g9();
  }

  public i10(): any {
    return this.i8() + this.i9();
  }

  public k10(): any {
    return this.k8() + this.k9();
  }

  public m10(): any {
    // This seems like a bug on the urban sheet
    if (this.rate === 'stateRate') {
      return +this.m8 + +this.m9;
    }
    return 92.63 * (this.currentTown[this.rate] || 1);
  }

  public d5(): any {
    return this.b5 * this.c5;
  }

  public d6() {
    return this.b6 * this.c6();
  }

  public f6() {
    return this.b6 * this.e6();
  }

  c9() {
    return this.c5 - this.c6();
  }

  d9() {
    return this.b9 * this.c9();
  }

  e9() {
    return this.e5 - this.e6();
  }

  f9() {
    return this.b9 * this.e9();
  }

  g9() {
    return this.g5 - this.g6();
  }

  h9() {
    return this.b9 * this.g9();
  }

  i9() {
    return this.i5 - this.i6();
  }

  j9() {
    return this.b9 * this.i9();
  }

  k9() {
    return this.k5 - this.k6();
  }

  l9() {
    return this.b9 * this.k9();
  }

  h8() {
    return this.b8 * this.g8();
  }

  i8() {
    return this.i6() * this.i7();
  }

  j8() {
    return this.b8 * this.i8();
  }

  k8() {
    return this.k6() * this.k7();
  }

  l8() {
    return this.b8 * this.k8();
  }

  g8() {
    return this.g6() * this.g7();
  }

  public h7() {
    return this.b7 * this.g7();
  }

  public f7() {
    return this.b7 * this.e7();
  }

  public f8() {
    return this.b8 * this.e8();
  }

  public h6() {
    return this.b6 * this.g6();
  }

  public d11(): any {
    return this.b11 * this.c10() * this.c4;
  }

  public d12(): any {
    return this.b12 * this.c10() * this.c4;
  }

  public d13(): any {
    return this.b13 * this.c10() * this.c4;
  }

  public d14(): any {
    return this.b14 * this.c10() * this.c4;
  }

  public d15(): any {
    return this.b15 * this.c10() * this.c4;
  }

  public d16(): any {
    return this.b16 * this.c10() * this.c4;
  }

  public d17(): any {
    return this.b17 * this.c10() * this.c4;
  }

  public d18(): any {
    return this.b18 * this.c10() * this.c4;
  }

  public d19(): any {
    return this.b19 * this.c10() * this.c4;
  }

  public d20(): any {
    return this.b20 * this.c10() * this.c4;
  }

  public d21(): any {
    return this.b21 * this.c10() * this.c4;
  }

  public d22(): any {
    return this.b22 * this.c10() * this.c4;
  }

  public d23(): any {
    return this.b23 * this.c10() * this.c4;
  }

  public d24(): any {
    return this.b24 * this.c10() * this.c4;
  }

  public b25() {
    return (this.b11 || 0) +
            (this.b12 || 0) +
            (this.b13 || 0) +
            (this.b14 || 0) +
            (this.b15 || 0) +
            (this.b16 || 0) +
            (this.b17 || 0) +
            (this.b18 || 0) +
            (this.b19 || 0) +
            (this.b20 || 0) +
            (this.b21 || 0) +
            (this.b22 || 0) +
            (this.b23 || 0) +
            (this.b24 || 0);
  }

  public e11(): any {
    return (this.e10() * this.e4) * 3;
  }

  public e12(): any {
    return this.e10() * this.e4;
  }

  public f5(): any {
    if (this.rate === 'stateRate') {
      return 0;
    }
    return this.b5 * this.e5;
  }

  public h5(): any {
    if (this.rate === 'stateRate') {
      return 0;
    }
    return this.b5 * this.g5;
  }

  public f11(): any {
    return this.b11 * this.e11();
  }

  public f12(): any {
    return this.b12 * this.e12();
  }

  public f13(): any {
    return this.b13 * this.e12();
  }

  public f14(): any {
    return this.b14 * this.e12();
  }

  public f15(): any {
    return this.b15 * this.e12();
  }

  public f16(): any {
    return this.b16 * this.e12();
  }

  public f17(): any {
    return this.b17 * this.e12();
  }

  public f18(): any {
    return this.b18 * this.e12();
  }

  public f19(): any {
    return this.b19 * this.e12();
  }

  public f20(): any {
    return this.b20 * this.e12();
  }

  public f21(): any {
    return this.b21 * this.e12();
  }

  public f22(): any {
    return this.b22 * this.e12();
  }

  public f23(): any {
    return this.b23 * this.e12();
  }

  public f24(): any {
    return this.b24 * this.e12();
  }

  public g11(): any {
    return this.g10() * this.g4;
  }

  public g12(): any {
    return this.g10() * this.g4;
  }

  public g13(): any {
    return this.g10() * this.g4 * 0.98;
  }

  public g14(): any {
    return this.g10() * this.g4 * 0.96;
  }

  public g15(): any {
    return this.g10() * this.g4 * 0.94;
  }

  public g16(): any {
    return this.g10() * this.g4 * 0.92;
  }

  public g17(): any {
    return this.g10() * this.g4 * 0.9;
  }

  public g18(): any {
    return this.g10() * this.g4 * 0.8;
  }

  public g19(): any {
    return this.g10() * this.g4 * 0.86;
  }

  public g20(): any {
    return this.g10() * this.g4 * 0.84;
  }

  public g21(): any {
    return this.g10() * this.g4 * 0.82;
  }

  public g22(): any {
    return this.g10() * this.g4 * 0.8;
  }

  public g23(): any {
    return this.g10() * this.g4 * 0.78;
  }

  public g24(): any {
    return this.g10() * this.g4 * 0.76;
  }

  public h11(): any {
    return this.b11 * this.g11();
  }

  public h12(): any {
    return this.b12 * this.g12();
  }

  public h13(): any {
    return this.b13 * this.g13();
  }

  public h14(): any {
    return this.b14 * this.g14();
  }

  public h15(): any {
    return this.b15 * this.g15();
  }

  public h16(): any {
    return this.b16 * this.g16();
  }

  public h17(): any {
    return this.b17 * this.g17();
  }

  public h18(): any {
    return this.b18 * this.g18();
  }

  public h19(): any {
    return this.b19 * this.g19();
  }

  public h20(): any {
    return this.b20 * this.g20();
  }

  public h21(): any {
    return this.b21 * this.g21();
  }

  public h22(): any {
    return this.b22 * this.g22();
  }

  public h23(): any {
    return this.b23 * this.g23();
  }

  public h24(): any {
    return this.b24 * this.g24();
  }

  public i11(): any {
    return this.i10() * this.i4;
  }

  public i12(): any {
    return this.i10() * this.i4;
  }

  public i13(): any {
    return this.i10() * this.i4 * 0.98;
  }

  public i14(): any {
    return this.i10() * this.i4 * 0.96;
  }

  public i15(): any {
    return this.i10() * this.i4 * 0.94;
  }

  public i16(): any {
    return this.i10() * this.i4 * 0.92;
  }

  public i17(): any {
    return this.i10() * this.i4 * 0.9;
  }

  public i18(): any {
    return this.i10() * this.i4 * 0.88;
  }

  public i19(): any {
    return this.i10() * this.i4 * 0.86;
  }

  public i20(): any {
    return this.i10() * this.i4 * 0.84;
  }

  public i21(): any {
    return this.i10() * this.i4 * 0.82;
  }

  public i22(): any {
    return this.i10() * this.i4 * 0.8;
  }

  public i23(): any {
    return this.i10() * this.i4 * 0.78;
  }

  public i24(): any {
    return this.i10() * this.i4 * 0.76;
  }

  public j7() {
    return this.b7 * this.i7();
  }

  public l7() {
    return this.b7 * this.k7();
  }

  public c8() {
    return this.c6() * this.c7();
  }

  public j5(): any {
    if (this.rate === 'stateRate') {
      return 0;
    }
    return this.b5 * this.i5;
  }

  public l5(): any {
    if (this.rate === 'stateRate') {
      return 0;
    }
    return this.b5 * this.k5;
  }

  public j11(): any {
    return this.b12 * this.i12();
  }

  public j12(): any {
    return this.b12 * this.i12();
  }

  public j13(): any {
    return this.b13 * this.i13();
  }

  public j14(): any {
    return this.b14 * this.i14();
  }

  public j15(): any {
    return this.b15 * this.i15();
  }

  public j16(): any {
    return this.b16 * this.i16();
  }

  public j17(): any {
    return this.b17 * this.i17();
  }

  public j18(): any {
    return this.b18 * this.i18();
  }

  public j19(): any {
    return this.b19 * this.i19();
  }

  public j20(): any {
    return this.b20 * this.i20();
  }

  public j21(): any {
    return this.b21 * this.i21();
  }

  public j22(): any {
    return this.b22 * this.i22();
  }

  public j23(): any {
    return this.b23 * this.i23();
  }

  public j24(): any {
    return this.b24 * this.i24();
  }

  public k11(): any {
    return this.k10() * this.k4;
  }

  public k12(): any {
    return this.k10() * this.k4;
  }

  public k13(): any {
    return this.k10() * this.k4;
  }

  public k14(): any {
    return this.k10() * this.k4;
  }

  public k15(): any {
    return this.k10() * this.k4;
  }

  public k16(): any {
    return this.k10() * this.k4;
  }

  public k17(): any {
    return this.k10() * this.k4;
  }

  public k18(): any {
    return this.k10() * this.k4;
  }

  public k19(): any {
    return this.k10() * this.k4;
  }

  public k20(): any {
    return this.k10() * this.k4;
  }

  public k21(): any {
    return this.k10() * this.k4;
  }

  public k22(): any {
    return this.k10() * this.k4;
  }

  public k23(): any {
    return this.k10() * this.k4;
  }

  public k24(): any {
    return this.k10() * this.k4;
  }

  public l11(): any {
    return this.b11 * this.k11();
  }

  public l12(): any {
    return this.b12 * this.k12();
  }

  public l13(): any {
    return this.b13 * this.k13();
  }

  public l14(): any {
    return this.b14 * this.k14();
  }

  public l15(): any {
    return this.b15 * this.k15();
  }

  public l16(): any {
    return this.b16 * this.k16();
  }

  public l17(): any {
    return this.b17 * this.k17();
  }

  public l18(): any {
    return this.b18 * this.k18();
  }

  public l19(): any {
    return this.b19 * this.k19();
  }

  public l20(): any {
    return this.b20 * this.k20();
  }

  public l21(): any {
    return this.b21 * this.k21();
  }

  public l22(): any {
    return this.b23 * this.k23();
  }

  public l23(): any {
    return this.b23 * this.k23();
  }

  public l24(): any {
    return this.b24 * this.k24();
  }

  public n11(): any {
    return this.b11 * this.m11();
  }

  public n12(): any {
    return this.b12 * this.m12();
  }

  public n13(): any {
    return this.b13 * this.m13();
  }

  public n14(): any {
    return this.b14 * this.m14();
  }

  public n15(): any {
    return this.b15 * this.m15();
  }

  public n16(): any {
    return this.b16 * this.m16();
  }

  public n17(): any {
    return this.b17 * this.m17();
  }

  public n18(): any {
    return this.b18 * this.m18();
  }

  public n19(): any {
    return this.b19 * this.m19();
  }

  public n20(): any {
    return this.b20 * this.m20();
  }

  public n21(): any {
    return this.b21 * this.m21();
  }

  public n22(): any {
    return this.b22 * this.m22();
  }

  public n23(): any {
    return this.b23 * this.m23();
  }

  public n24(): any {
    return this.b24 * this.m24();
  }

  public o11(): any {
    return this.d11() + this.f11() + this.h11() + this.j11() + this.l11() + this.n11();
  }

  public o12(): any {
    return this.d12() + this.f12() + this.h12() + this.j12() + this.l12() + this.n12();
  }

  public o13(): any {
    return this.d13() + this.f13() + this.h13() + this.j13() + this.l13() + this.n13();
  }

  public o14(): any {
    return this.d14() + this.f14() + this.h14() + this.j14() + this.l14() + this.n14();
  }

  public o15(): any {
    return this.d15() + this.f15() + this.h15() + this.j15() + this.l15() + this.n15();
  }

  public o16(): any {
    return this.d16() + this.f16() + this.h16() + this.j16() + this.l16() + this.n16();
  }

  public o17(): any {
    return this.d17() + this.f17() + this.h17() + this.j17() + this.l17() + this.n17();
  }

  public o18(): any {
    return this.d18() + this.f18() + this.h18() + this.j18() + this.l18() + this.n18();
  }

  public o19(): any {
    return this.d19() + this.f19() + this.h19() + this.j19() + this.l19() + this.n19();
  }

  public o20(): any {
    return this.d20() + this.f20() + this.h20() + this.j20() + this.l20() + this.n20();
  }

  public o21(): any {
    return this.d21() + this.f21() + this.h21() + this.j21() + this.l21() + this.n21();
  }

  public o22(): any {
    return this.d22() + this.f22() + this.h22() + this.j22() + this.l22() + this.n22();
  }

  public o23(): any {
    return this.d23() + this.f23() + this.h23() + this.j23() + this.l23() + this.n23();
  }

  public o24(): any {
    return this.d24() + this.f24() + this.h24() + this.j24() + this.l24() + this.n24();
  }

  public m11(): any {
    return this.m10();
  }

  public m12(): any {
    return this.m11();
  }

  public m13(): any {
    return this.m12();
  }

  public m14(): any {
    return this.m13();
  }

  public m15(): any {
    return this.m14();
  }

  public m16(): any {
    return this.m15();
  }

  public m17(): any {
    return this.m16();
  }

  public m18(): any {
    return this.m17();
  }

  public m19(): any {
    return this.m18();
  }

  public m20(): any {
    return this.m19();
  }

  public m21(): any {
    return this.m20();
  }

  public m22(): any {
    return this.m21();
  }

  public m23(): any {
    return this.m22();
  }

  public m24(): any {
    return this.m23();
  }

  public d25(): any {
    return (this.d11() || 0) +
      (this.d12() || 0) +
      (this.d13() || 0) +
      (this.d14() || 0) +
      (this.d15() || 0) +
      (this.d16() || 0) +
      (this.d17() || 0) +
      (this.d18() || 0) +
      (this.d19() || 0) +
      (this.d20() || 0) +
      (this.d21() || 0) +
      (this.d22() || 0) +
      (this.d23() || 0) +
      (this.d24() || 0);
  }

  public f25(): any {
    return (this.f11() || 0) +
      (this.f12() || 0) +
      (this.f13() || 0) +
      (this.f14() || 0) +
      (this.f15() || 0) +
      (this.f16() || 0) +
      (this.f17() || 0) +
      (this.f18() || 0) +
      (this.f19() || 0) +
      (this.f20() || 0) +
      (this.f21() || 0) +
      (this.f22() || 0) +
      (this.f23() || 0) +
      (this.f24() || 0);
  }

  public h25(): any {
    return (this.h11() || 0) +
      (this.h12() || 0) +
      (this.h13() || 0) +
      (this.h14() || 0) +
      (this.h15() || 0) +
      (this.h16() || 0) +
      (this.h17() || 0) +
      (this.h18() || 0) +
      (this.h19() || 0) +
      (this.h20() || 0) +
      (this.h21() || 0) +
      (this.h22() || 0) +
      (this.h23() || 0) +
      (this.h24() || 0);
  }

  public j25(): any {
    return (this.j11() || 0) +
      (this.j12() || 0) +
      (this.j13() || 0) +
      (this.j14() || 0) +
      (this.j15() || 0) +
      (this.j16() || 0) +
      (this.j17() || 0) +
      (this.j18() || 0) +
      (this.j19() || 0) +
      (this.j20() || 0) +
      (this.j21() || 0) +
      (this.j22() || 0) +
      (this.j23() || 0) +
      (this.j24() || 0);
  }

  public l25(): any {
    return (this.l11() || 0) +
      (this.l12() || 0) +
      (this.l13() || 0) +
      (this.l14() || 0) +
      (this.l15() || 0) +
      (this.l16() || 0) +
      (this.l17() || 0) +
      (this.l18() || 0) +
      (this.l19() || 0) +
      (this.l20() || 0) +
      (this.l21() || 0) +
      (this.l22() || 0) +
      (this.l23() || 0) +
      (this.l24() || 0);
  }

  public n25(): any {
    return (this.n11() || 0) +
      (this.n12() || 0) +
      (this.n13() || 0) +
      (this.n14() || 0) +
      (this.n15() || 0) +
      (this.n16() || 0) +
      (this.n17() || 0) +
      (this.n18() || 0) +
      (this.n19() || 0) +
      (this.n20() || 0) +
      (this.n21() || 0) +
      (this.n22() || 0) +
      (this.n23() || 0) +
      (this.n24() || 0);
  }

  public o25(): any {
    return (this.d25() || 0) +
      (this.f25() || 0) +
      (this.h25() || 0) +
      (this.j25() || 0) +
      (this.l25() || 0) +
      (this.n25() || 0);
  }

  public n5(): any {
    return this.b5 * this.m5;
  }

  public n6(): any {
    return this.b6 * this.m6;
  }

  public n7(): any {
    return this.b7 * this.m7;
  }

  public n8(): any {
    return this.b8 * this.m8;
  }

  public n9(): any {
    return this.b9 * this.m9;
  }

  public setType(rate: string): any {
    this.rate = rate;
    if (rate === 'countyRate') {
      this.c5 = 98.83;
      this.e5 = 98.83;
      this.g5 = 98.83;
      this.i5 = 98.83;
      this.k5 = 98.83;
    } else {
      this.c5 = 98.83;
      this.e5 = 74.56;
      this.g5 = 62.11;
      this.i5 = 67.63;
      this.k5 = 27.90;
    }
  }

  public isOutOfRange(field, min, max) {
    // tslint:disable-next-line:radix
    const val = parseInt(field);

    return val < min || val > max;
  }
}
