<div class="row">
    <div class="col-1">
        <p>County/State:</p>
    </div>
    <div class="col-4">
        <select #townSelect class="form-control form-control-sm" (change)="townChange(townSelect.value)">
            <option *ngFor="let town of townsData" [value]="town.town">{{town.town}}</option>
        </select>
    </div>
    <div class="col-4">
        <button class="btn btn-secondary btn-sm" (click)="setType('countyRate')" [disabled]="rate === 'countyRate'">Urban</button>
        <button class="btn btn-secondary btn-sm" (click)="setType('stateRate')" [disabled]="rate === 'stateRate'">Rural</button>
    </div>
</div>
<div clas="row">
    <div clas="col-12">
        <table class="table table-responsive table-striped">
            <thead>
                <tr>
                    <th>Payment Day</th>
                    <th>N/A</th>
                    <th>Nursing Daily Rate</th>
                    <th>Nursing Totals</th>
                    <th>NTA Daily Rate</th>
                    <th>NTA Totals</th>
                    <th>OT Daily Rate</th>
                    <th>OT Totals</th>
                    <th>PT Daily Rate</th>
                    <th>PT Totals</th>
                    <th>SLP Daily Rate</th>
                    <th>SLP Totals</th>
                    <th>NCMI Daily Rate</th>
                    <th>NCMI Totals</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>CMI</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="b4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="c4"></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="d4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="e4"></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="f4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="g4"></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="h4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="i4"></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="j4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="k4"></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="l4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="m4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="n4" disabled></td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="o4" disabled></td>
                </tr>
                <tr>
                    <td>Rate</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="b5" disabled></td>
                    <td>{{c5 | currency }}</td>
                    <td>{{d5() | currency}}</td>
                    <td>{{e5 | currency }}</td>
                    <td>{{f5() | currency}}</td>
                    <td>{{g5 | currency }}</td>
                    <td>{{h5() | currency}}</td>
                    <td>{{i5 | currency }}</td>
                    <td>{{j5() | currency}}</td>
                    <td>{{k5 | currency }}</td>
                    <td>{{l5() | currency}}</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="m5" disabled></td>
                    <td>{{n5() | currency}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Labor Portion</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="b6" disabled></td>
                    <td>{{c6() | currency}}</td>
                    <td>{{d6() | currency}}</td>
                    <td>{{e6() | currency}}</td>
                    <td>{{f6() | currency}}</td>
                    <td>{{g6() | currency}}</td>
                    <td>{{h6() | currency}}</td>
                    <td>{{i6() | currency}}</td>
                    <td>{{j6() | currency}}</td>
                    <td>{{k6() | currency}}</td>
                    <td>{{l6() | currency}}</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="m6" disabled></td>
                    <td>{{n6() | currency}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Wage Index</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="b7" disabled></td>
                    <td>{{c7()}}</td>
                    <td>{{d7() | currency}}</td>
                    <td>{{e7()}}</td>
                    <td>{{f7() | currency}}</td>
                    <td>{{g7()}}</td>
                    <td>{{h7() | currency}}</td>
                    <td>{{i7()}}</td>
                    <td>{{j7() | currency}}</td>
                    <td>{{k7()}}</td>
                    <td>{{l7() | currency}}</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="m7" disabled></td>
                    <td>{{n7() | currency}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Wage Index Rate</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="b8" disabled></td>
                    <td>{{c8() | currency}}</td>
                    <td>{{d8() | currency}}</td>
                    <td>{{e8() | currency}}</td>
                    <td>{{f8() | currency}}</td>
                    <td>{{g8() | currency}}</td>
                    <td>{{h8() | currency}}</td>
                    <td>{{i8() | currency}}</td>
                    <td>{{j8() | currency}}</td>
                    <td>{{k8() | currency}}</td>
                    <td>{{l8() | currency}}</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="m8" disabled></td>
                    <td>{{n8() | currency}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Non Labor Portion</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="b9" disabled></td>
                    <td>{{c9() | currency}}</td>
                    <td>{{d9() | currency}}</td>
                    <td>{{e9() | currency}}</td>
                    <td>{{f9() | currency}}</td>
                    <td>{{g9() | currency}}</td>
                    <td>{{h9() | currency}}</td>
                    <td>{{i9() | currency}}</td>
                    <td>{{j9() | currency}}</td>
                    <td>{{k9() | currency}}</td>
                    <td>{{l9() | currency}}</td>
                    <td><input class="form-control form-control-sm" [(ngModel)]="m9" disabled></td>
                    <td>{{n9() | currency}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td>Day #</td>
                    <td>{{c10() | currency}}</td>
                    <td>&nbsp;</td>
                    <td>{{e10() | currency}}</td>
                    <td>&nbsp;</td>
                    <td>{{g10() | currency}}</td>
                    <td>&nbsp;</td>
                    <td>{{i10() | currency}}</td>
                    <td>&nbsp;</td>
                    <td>{{k10() | currency}}</td>
                    <td>&nbsp;</td>
                    <td>{{m10() | currency}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>1-3</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b11" type="number" min="1" max="3">
                        <p *ngIf="isOutOfRange(b11, 1, 3)">1-3!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{d11() | currency}}</td>
                    <td>{{(e10() * e4) * 3 | currency}}</td>
                    <td>{{f11() | currency}}</td>
                    <td>{{g11() | currency}}</td>
                    <td>{{h11() | currency}}</td>
                    <td>{{i11() | currency}}</td>
                    <td>{{j11() | currency}}</td>
                    <td>{{k11() | currency}}</td>
                    <td>{{l11() | currency}}</td>
                    <td>{{m11() | currency}}</td>
                    <td>{{n11() | currency}}</td>
                    <td>{{o11() | currency}}</td>
                </tr>
                <tr>
                    <td>4-20</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b12" type="number" min="1" max="17">
                        <p *ngIf="isOutOfRange(b12, 1, 17)">1-17!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{d12() | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f12() | currency}}</td>
                    <td>{{g12() | currency}}</td>
                    <td>{{h12() | currency}}</td>
                    <td>{{i12() | currency}}</td>
                    <td>{{j12() | currency}}</td>
                    <td>{{k12() | currency}}</td>
                    <td>{{l12() | currency}}</td>
                    <td>{{m12() | currency}}</td>
                    <td>{{n12() | currency}}</td>
                    <td>{{o12() | currency}}</td>
                </tr>
                <tr>
                    <td>21-27</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b13" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b13, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b13 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f13() | currency}}</td>
                    <td>{{g13() | currency}}</td>
                    <td>{{h13() | currency}}</td>
                    <td>{{i13() | currency}}</td>
                    <td>{{j13() | currency}}</td>
                    <td>{{k13() | currency}}</td>
                    <td>{{l13() | currency}}</td>
                    <td>{{m13() | currency}}</td>
                    <td>{{n13() | currency}}</td>
                    <td>{{o13() | currency}}</td>
                </tr>
                <tr>
                    <td>28-34</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b14" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b14, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b14 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f14() | currency}}</td>
                    <td>{{g14() | currency}}</td>
                    <td>{{h14() | currency}}</td>
                    <td>{{i14() | currency}}</td>
                    <td>{{j14() | currency}}</td>
                    <td>{{k14() | currency}}</td>
                    <td>{{l14() | currency}}</td>
                    <td>{{m14() | currency}}</td>
                    <td>{{n14() | currency}}</td>
                    <td>{{o14() | currency}}</td>
                </tr>
                <tr>
                    <td>35-41</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b15" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b15, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b15 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f15() | currency}}</td>
                    <td>{{g15() | currency}}</td>
                    <td>{{h15() | currency}}</td>
                    <td>{{i15() | currency}}</td>
                    <td>{{j15() | currency}}</td>
                    <td>{{k15() | currency}}</td>
                    <td>{{l15() | currency}}</td>
                    <td>{{m15() | currency}}</td>
                    <td>{{n15() | currency}}</td>
                    <td>{{o15() | currency}}</td>
                </tr>
                <tr>
                    <td>42-48</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b16" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b16, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b16 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f16() | currency}}</td>
                    <td>{{g16() | currency}}</td>
                    <td>{{h16() | currency}}</td>
                    <td>{{i16() | currency}}</td>
                    <td>{{j16() | currency}}</td>
                    <td>{{k16() | currency}}</td>
                    <td>{{l16() | currency}}</td>
                    <td>{{m16() | currency}}</td>
                    <td>{{n16() | currency}}</td>
                    <td>{{o16() | currency}}</td>
                </tr>
                <tr>
                    <td>49-55</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b17" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b17, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b17 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f17() | currency}}</td>
                    <td>{{g17() | currency}}</td>
                    <td>{{h17() | currency}}</td>
                    <td>{{i17() | currency}}</td>
                    <td>{{j17() | currency}}</td>
                    <td>{{k17() | currency}}</td>
                    <td>{{l17() | currency}}</td>
                    <td>{{m17() | currency}}</td>
                    <td>{{n17() | currency}}</td>
                    <td>{{o17() | currency}}</td>
                </tr>
                <tr>
                    <td>56-62</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b18" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b18, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b18 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f18() | currency}}</td>
                    <td>{{g18() | currency}}</td>
                    <td>{{h18() | currency}}</td>
                    <td>{{i18() | currency}}</td>
                    <td>{{j18() | currency}}</td>
                    <td>{{k18() | currency}}</td>
                    <td>{{l18() | currency}}</td>
                    <td>{{m18() | currency}}</td>
                    <td>{{n18() | currency}}</td>
                    <td>{{o18() | currency}}</td>
                </tr>
                <tr>
                    <td>63-69</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b19" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b19, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b19 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f19() | currency}}</td>
                    <td>{{g19() | currency}}</td>
                    <td>{{h19() | currency}}</td>
                    <td>{{i19() | currency}}</td>
                    <td>{{j19() | currency}}</td>
                    <td>{{k19() | currency}}</td>
                    <td>{{l19() | currency}}</td>
                    <td>{{m19() | currency}}</td>
                    <td>{{n19() | currency}}</td>
                    <td>{{o19() | currency}}</td>
                </tr>
                <tr>
                    <td>70-76</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b20" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b20, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b20 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f20() | currency}}</td>
                    <td>{{g20() | currency}}</td>
                    <td>{{h20() | currency}}</td>
                    <td>{{i20() | currency}}</td>
                    <td>{{j20() | currency}}</td>
                    <td>{{k20() | currency}}</td>
                    <td>{{l20() | currency}}</td>
                    <td>{{m20() | currency}}</td>
                    <td>{{n20() | currency}}</td>
                    <td>{{o20() | currency}}</td>
                </tr>
                <tr>
                    <td>77-83</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b21" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b21, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b21 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f21() | currency}}</td>
                    <td>{{g21() | currency}}</td>
                    <td>{{h21() | currency}}</td>
                    <td>{{i21() | currency}}</td>
                    <td>{{j21() | currency}}</td>
                    <td>{{k21() | currency}}</td>
                    <td>{{l21() | currency}}</td>
                    <td>{{m21() | currency}}</td>
                    <td>{{n21() | currency}}</td>
                    <td>{{o21() | currency}}</td>
                </tr>
                <tr>
                    <td>84-90</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b22" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b22, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b22 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f22() | currency}}</td>
                    <td>{{g22() | currency}}</td>
                    <td>{{h22() | currency}}</td>
                    <td>{{i22() | currency}}</td>
                    <td>{{j22() | currency}}</td>
                    <td>{{k22() | currency}}</td>
                    <td>{{l22() | currency}}</td>
                    <td>{{m22() | currency}}</td>
                    <td>{{n22() | currency}}</td>
                    <td>{{o22() | currency}}</td>
                </tr>
                <tr>
                    <td>91-97</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b23" type="number" min="1" max="7">
                        <p *ngIf="isOutOfRange(b23, 1, 7)">1-7!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{b23 * c10() * c4 | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f23() | currency}}</td>
                    <td>{{g23() | currency}}</td>
                    <td>{{h23() | currency}}</td>
                    <td>{{i23() | currency}}</td>
                    <td>{{j24() | currency}}</td>
                    <td>{{k23() | currency}}</td>
                    <td>{{l23() | currency}}</td>
                    <td>{{m23() | currency}}</td>
                    <td>{{n23() | currency}}</td>
                    <td>{{o23() | currency}}</td>
                </tr>
                <tr>
                    <td>98-100</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="b24" type="number" min="1" max="3">
                        <p *ngIf="isOutOfRange(b24, 1, 3)">1-3!</p>
                    </td>
                    <td>{{c11() | currency}}</td>
                    <td>{{d24() | currency}}</td>
                    <td>{{e12() | currency}}</td>
                    <td>{{f24() | currency}}</td>
                    <td>{{g24() | currency}}</td>
                    <td>{{h24() | currency}}</td>
                    <td>{{i24() | currency}}</td>
                    <td>{{j24() | currency}}</td>
                    <td>{{k24() | currency}}</td>
                    <td>{{l24() | currency}}</td>
                    <td>{{m24() | currency}}</td>
                    <td>{{n24() | currency}}</td>
                    <td>{{o24() | currency}}</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>{{b25()}}</td>
                    <td></td>
                    <td>{{d25() | currency}}</td>
                    <td></td>
                    <td>{{f25() | currency}}</td>
                    <td></td>
                    <td>{{h25() | currency}}</td>
                    <td></td>
                    <td>{{j25() | currency}}</td>
                    <td></td>
                    <td>{{l25() | currency}}</td>
                    <td></td>
                    <td>{{n25() | currency}}</td>
                    <td>{{o25() | currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
